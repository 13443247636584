import './topbar.scss'

export default function Topbar() {
    return(
        <div className='topbar'>
            <div className='wrapper'>
                <div href='#intro' className='left'>
                    <a href='#intro' className='mannylogo'>MM</a>
                </div>
                <div className='right'>

                </div>
            </div>

        </div>
    )
}